import React, { Component } from 'react';
import './NotFound.scss';
import { Link } from 'react-router-dom';
import { Footer } from '../../components';
import ReactPlayer from 'react-player';

class NotFound extends Component {
  render() {
    return (
      <div className="error">
        <div className="video-ctnr">
          <ReactPlayer
            width={'100%'}
            height={'100vh'}
            url={`https://youtu.be/t4Y6v0ouVoA?autoplay=1&controls=0&loop=1&playlist=NulgtJrRfPQ&playsinline=1&mute=1&modestbranding=1`}
            playing={true}
            loop={true}
            playsinline={true}
            muted={true}
            className={'react-player error__video'}
            config={{
              youtube: {
                playerVars: { rel: 0, controls: 0 },
                embedOptions: {
                  host: 'https://www.youtube-nocookie.com'
                }
              }
            }}
          />
        </div>
        <div className="error__copy yellow bg--red">
          <h1 className="error__headline">404</h1>
          <p className="headline headline--italic">
            Pick yourself up and head back home.
          </p>
          <Link className="btn btn--yellow" to="/">
            <span className="arrow" />Go Home
          </Link>
          <a className="btn btn--yellow" href={`javascript:history.back()`}>
            <span className="arrow" />Previous Page
          </a>
        </div>
        <Footer margin="none" />
      </div>
    );
  }
}

export default NotFound;
